import React, { ReactElement, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import {
  SpaceProps,
  space,
  LayoutProps,
  layout,
  PositionProps,
  position,
} from 'styled-system';

type StyledCheckRadioProps = SpaceProps &
  LayoutProps &
  PositionProps & {
    inline?: boolean;
    disabled?: boolean;
    label?: string;
    indeterminate?: boolean;
  };

export const StyledCheckboxWrapper = styled.div<StyledCheckRadioProps>`
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));

  --body-line-height: 1.4;

  --checkbox-radio-size: 18px;
  --checkbox-radio-gap: ${({ theme }) => theme.space[1]};
  --checkbox-radio-border-width: 2px;
  --checkbox-radio-line-height: var(--body-line-height);

  --checkbox-marker-color: var(--color-on-primary);
  --checkbox-marker-size: 12px;
  --checkbox-radius: 4px;

  --color-bg: hsl(0, 0%, 100%);

  position: relative;
  display: flex;
  align-items: center;

  ${({ inline }) =>
    inline &&
    css`
      display: inline-block;
    `}

  &.pb-cb__link-procedure {
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[8]};
    border-top: solid 1px var(--color-neutral-contrast-03);

    input[type='checkbox'] + label::before {
      margin-right: ${({ theme }) => theme.space[2]};
    }

    label > span {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  }

  &.pb-cb__import-procedure {
    flex: 1;
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
    border-bottom: solid 1px var(--color-neutral-contrast-03);

    html[data-color-mode='dark'] & {
      border-bottom: solid 1px var(--color-neutral-contrast-02);
    }

    input[type='checkbox'] + label::before {
      margin-right: ${({ theme }) => theme.space[3]};
    }

    label > span {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 210px;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }

  &.pb-cb__import_existing-procedure {
    flex: 1;
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[8]};
    padding-right: ${({ theme }) => theme.space[2]};
    border-bottom: solid 1px var(--color-neutral-contrast-03);

    html[data-color-mode='dark'] & {
      border-bottom: solid 1px var(--color-neutral-contrast-02);
    }

    input[type='checkbox'] + label::before {
      margin-right: ${({ theme }) => theme.space[3]};
    }

    label > span {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 210px;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
    font-size: 14px;

    & + label {
      display: inline-flex;
      align-items: flex-start;
      line-height: var(--checkbox-radio-line-height);
      user-select: none;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.text.body};
      word-break: break-all;
    }

    & + label::before {
      content: '';
      display: inline-block;
      position: relative;

      /* top: ${({ label }) =>
        label === ''
          ? '5px'
          : `calc(
        (1em * var(--checkbox-radio-line-height) - var(--checkbox-radio-size)) /
          2
      )`}; */

      flex-shrink: 0;
      width: var(--checkbox-radio-size);
      height: var(--checkbox-radio-size);
      background-color: var(--color-neutral-contrast-01);
      border-width: var(--checkbox-radio-border-width);
      border-color: var(--color-neutral-contrast-05);
      border-style: solid;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: var(--checkbox-radio-gap);
      transition: transform 0.2s, border 0.2s;

      .pb-link-account & {
        margin-right: ${({ theme }) => theme.space[3]};
      }
    }

    &:not(:checked):not(:focus):not(:disabled) + label:hover::before {
      border-color: var(--color-neutral-contrast-05);
      /* cursor: not-allowed; */
    }

    & + label::before {
      border-radius: var(--checkbox-radius);
    }

    &:checked + label::before {
      background-color: ${({ theme }) => theme.colors.primary};
      box-shadow: none;
      border-color: ${({ theme }) => theme.colors.primary};
      transition: transform 0.2s;
      z-index: 2;
    }

    &:active:not(:disabled) + label::before {
      transform: scale(0.8);
      transition: transform 0.2s;
    }

    &:checked:active + label::before {
      transform: none;
      transition: none;
    }

    &:checked + label::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolyline points='1 6.5 4 9.5 11 2.5' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
      background-size: var(--checkbox-marker-size);
    }

    &:checked:active + label::before,
    &:focus + label::before {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0 3px hsla(var(--primary-color-hsl), 30%);
    }

    &:disabled + label {
      opacity: 0.5;
      cursor: not-allowed;
    }

    /* &:not(:checked):not(:focus):not(:focus) + label:hover::before {
      border-color: var(--upper-low-contrast-neutral);
    } */

    ${({ indeterminate }) =>
      indeterminate &&
      css`
        & + label {
          position: relative;
        }

        & + label::before,
        &:hover + label::before {
          border-color: ${({ theme }) => theme.colors.primary} !important;
        }

        & + label::after {
          content: '';
          width: 8px;
          height: 2px;
          position: absolute;
          top: 48%;
          left: 9px;
          transform: translate(-50%, -50%);
          background: ${({ theme }) => theme.colors.primary};
          z-index: 1;
        }
      `}
  }
  ${space}
  ${layout}
  ${position}
`;

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

type ssProps = SpaceProps & LayoutProps & PositionProps;

export type CheckboxProps = ssProps & {
  name?: string;
  label?: string | any;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  inline?: boolean;
  handleChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  register?: () => RefReturn;
  className?: string;
  title?: string;
  indeterminate?: boolean;
};

function Checkbox({
  name,
  label,
  value,
  checked,
  disabled,
  inline,
  handleChange,
  register,
  className,
  title,
  indeterminate,
  ...ssProps
}: CheckboxProps): ReactElement {
  // const id = `cb-${label.replace(/ /g, '').toLowerCase()}`;

  return (
    <StyledCheckboxWrapper
      inline={inline}
      label={label}
      className={className}
      indeterminate={indeterminate}
      {...ssProps}
    >
      <input
        data-testid="checkbox"
        name={name}
        ref={register && register()}
        type="checkbox"
        id={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e: SyntheticEvent<HTMLInputElement>) =>
          handleChange && handleChange(e)
        }
      />
      <label htmlFor={name} title={title}>
        <span>{label}</span>
      </label>
    </StyledCheckboxWrapper>
  );
}

export { Checkbox };
