/* eslint-disable react/display-name */
import React from 'react';
import { Column, TableInstance, ColumnInstance } from 'react-table';
import dayjs, { Dayjs } from 'dayjs';
import { TableType } from 'types/countryType';
import Header from './tableHeader';
import TelephonyLogOptions from './tableTelephonyLogOptions';
import { getFormatDate } from 'utils/dateHelper';

export default function useTelephonyLogColumns(
  columnsList?: Array<string>,
  // skipOptions?: boolean,
): Array<Column<TableType>> {
  return React.useMemo((): Array<Column<TableType>> => {
    if (columnsList) {
      const columns: any = [];
      columnsList.forEach(columnName => {
        if (columnName === 'Account Name') columns.push(getIDHeader(Header));
        if (columnName === 'CID') columns.push(getCIDHeader(Header));
        if (columnName === 'PID') columns.push(getPIDHeader(Header));
        if (columnName === 'SID') columns.push(getSIDHeader(Header));
        if (columnName === 'Telephony Category')
          columns.push(getTelephonyCategoryHeader(Header));
        if (columnName === 'Previous Value')
          columns.push(getPreviousValueHeader(Header));
        if (columnName === 'New Value') columns.push(getNewValueHeader(Header));
        if (columnName === 'Updated By')
          columns.push(getUpdatedByHeader(Header));
        if (columnName === 'Updated At')
          columns.push(getUpdatedAtHeader(Header));
      });
      // if (!skipOptions) columns.push(getOptionsHeader());
      return columns;
    }
    return [
      getIDHeader(Header),
      getCIDHeader(Header),
      getSIDHeader(Header),
      getPIDHeader(Header),
      getTelephonyCategoryHeader(Header),
      getPreviousValueHeader(Header),
      getNewValueHeader(Header),
      getUpdatedByHeader(Header),
      getUpdatedAtHeader(Header),
      // getOptionsHeader(),
    ];
  }, [columnsList]);
}

function getIDHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    Header: Header,
    accessor: (row: TableType) => `${row.AccountName || ''}`,
    id: 'Account Name',
  };
}

function getCIDHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    Header: Header,
    accessor: (row: TableType) => `${row?.CID || ''}`,
    id: 'CID',
  };
}
function getPIDHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    Header: Header,
    accessor: (row: TableType) => `${row?.PID || ''}`,
    id: 'PID',
  };
}

function getSIDHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    Header: Header,
    accessor: (row: TableType) => `${row?.SID || ''}`,
    id: 'SID',
  };
}

function getTelephonyCategoryHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    Header: Header,
    accessor: (row: TableType | any) => `${row?.TelephonyCategory || ''}`,
    id: 'Telephony Category',
  };
}

function getPreviousValueHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    id: 'Previous Value',
    Header: Header,
    accessor: (row: TableType | any) => `${row.PreviousValue || ''}`,
  };
}

function getNewValueHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    id: 'New Value',
    Header: Header,
    accessor: (row: TableType | any) => `${row.CurrentValue || ''}`,
  };
}

function getUpdatedByHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    Header: Header,
    accessor: (row: TableType | any) => `${row.UpdatedBy || ''}`,
    id: 'Updated By',
  };
}

function getUpdatedAtHeader(
  Header: (
    props: TableInstance<TableType> & {
      column: ColumnInstance<TableType>;
    },
  ) => JSX.Element,
): Column<TableType> {
  return {
    Header: Header,
    accessor: (row: TableType | any) =>
      dayjs(`${row.UpdatedAt}`).format('MM/DD/YYYY HH:mm:ss'),
    id: 'Updated At',
  };
}

// function getOptionsHeader(): Column<Forms> {
//   return {
//     id: 'Options',
//     Header: 'Action',
//     Cell: TelephonyLogOptions,
//   };
// }
