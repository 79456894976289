import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import dayjs from 'dayjs';
import { Questions } from 'types/questions';
import { getTelephonyGrid } from '../utils/urls';
import { getTokenWithType } from './useAuth';

const pageLength = 100;

function getColumnFromId(id: string) {
  const idsList = [
    'Id',
    'Account Name',
    'CID',
    'PID',
    'SID',
    'Telephony Category',
    'Previous Value',
    'New Value',
    'Updated By',
    'Updated At',
  ];
  const index = idsList.findIndex(item => item === id);
  return `${index}`;
}

async function fetchTwilioDetails({
  requestData,
}: TVariables): Promise<TResult> {
  const response = await fetch(getTelephonyGrid, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: requestData,
  });
  return response.json();
}

type resultType = {
  data: Array<any>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<any>;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
};

function formatRequestData(
  page: number,
  columnsSearch: Record<string, string>,
  currentSortItem: Record<string, string>,
  // sortOrder: string,
): FormData {
  console.log('columnsSearch', columnsSearch, currentSortItem);
  const formData: any = new URLSearchParams();
  formData.append('draw', '0');
  formData.append('length', `${pageLength}`);
  formData.append('timezoneOffset', `${new Date().getTimezoneOffset()}`);
  formData.append('columns[0][data]', 'Id');
  formData.append('columns[0][name]', 'Id');
  formData.append('columns[0][searchable]', 'true');
  formData.append('columns[0][orderable]', 'true');
  formData.append('columns[0][search][regex]', 'false');
  formData.append('columns[0][search][value]', '');

  formData.append('columns[1][data]', 'AccountName');
  formData.append('columns[1][name]', '');
  formData.append('columns[1][searchable]', 'true');
  formData.append('columns[1][orderable]', 'true');
  formData.append('columns[1][search][regex]', 'false');
  formData.append(
    'columns[1][search][value]',
    columnsSearch['Account Name'] ? columnsSearch['Account Name'] : '',
  );

  formData.append('columns[2][data]', 'CID');
  formData.append('columns[2][name]', '');
  formData.append('columns[2][searchable]', 'true');
  formData.append('columns[2][orderable]', 'true');
  formData.append('columns[2][search][regex]', 'false');
  formData.append(
    'columns[2][search][value]',
    columnsSearch['CID'] ? columnsSearch['CID'] : '',
  );

  formData.append('columns[3][data]', 'PID');
  formData.append('columns[3][name]', '');
  formData.append('columns[3][searchable]', 'true');
  formData.append('columns[3][orderable]', 'true');
  formData.append('columns[3][search][regex]', 'false');
  formData.append(
    'columns[3][search][value]',
    columnsSearch['PID'] ? columnsSearch['PID'] : '',
  );

  formData.append('columns[4][data]', 'SID');
  formData.append('columns[4][name]', '');
  formData.append('columns[4][searchable]', 'true');
  formData.append('columns[4][orderable]', 'true');
  formData.append('columns[4][search][regex]', 'false');
  formData.append(
    'columns[4][search][value]',
    columnsSearch['SID'] ? columnsSearch['SID'] : '',
  );

  formData.append('columns[5][data]', 'TelePhonyCategory');
  formData.append('columns[5][name]', '');
  formData.append('columns[5][searchable]', 'true');
  formData.append('columns[5][orderable]', 'true');
  formData.append('columns[5][search][regex]', 'false');
  formData.append(
    'columns[5][search][value]',
    columnsSearch['Telephony Category']
      ? columnsSearch['Telephony Category']
      : '',
  );

  formData.append('columns[6][data]', 'PreviousValue');
  formData.append('columns[6][name]', '');
  formData.append('columns[6][searchable]', 'true');
  formData.append('columns[6][orderable]', 'true');
  formData.append('columns[6][search][regex]', 'false');
  formData.append(
    'columns[6][search][value]',
    columnsSearch['Previous Value'] ? columnsSearch['Previous Value'] : '',
  );

  formData.append('columns[7][data]', 'CurrentValue');
  formData.append('columns[7][name]', '');
  formData.append('columns[7][searchable]', 'true');
  formData.append('columns[7][orderable]', 'true');
  formData.append('columns[7][search][regex]', 'false');
  formData.append(
    'columns[7][search][value]',
    columnsSearch['New Value'] ? columnsSearch['New Value'] : '',
  );

  formData.append('columns[8][data]', 'UpdatedBy');
  formData.append('columns[8][name]', '');
  formData.append('columns[8][searchable]', 'true');
  formData.append('columns[8][orderable]', 'true');
  formData.append('columns[8][search][regex]', 'false');
  formData.append(
    'columns[8][search][value]',
    columnsSearch['Updated By'] ? columnsSearch['Updated By'] : '',
  );

  formData.append('columns[9][data]', 'UpdatedAt');
  formData.append('columns[9][name]', '');
  formData.append('columns[9][searchable]', 'true');
  formData.append('columns[9][orderable]', 'true');
  formData.append('columns[9][search][regex]', 'false');
  formData.append(
    'columns[9][search][value]',
    columnsSearch['Updated At']
      ? dayjs(columnsSearch['Updated At']).format('MM/DD/YYYY')
      : '',
  );

  formData.append('start', `${page * 100}`);
  formData.append(
    'order[0][column]',
    getColumnFromId(currentSortItem?.sortKey),
  );
  formData.append('order[0][dir]', currentSortItem?.sortOrder);

  formData.append('search[value]', '');
  formData.append('search[regex]', 'false');

  return formData;
}

function useGetTelephonyGrid({
  columnsSearch,
  currentSortItem,
  setLastUpdated,
}: {
  columnsSearch: Record<string, string>;
  currentSortItem: { sortKey: string; sortOrder: string };
  setLastUpdated: any;
}): InfiniteQueryResult<TResult, TError> {
  let permissions = [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissions = JSON.parse(permissionSet);
  }
  const procedureName = process.env.REACT_APP_THEME;

  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'telephony_list',
    columnsSearch,
    currentSortItem,
  ]);

  let start = data ? data.length : 0;
  if (start > 0 && data) {
    const totalPages = data[0].recordsTotal / pageLength;
    if (totalPages > 1) {
      start = start > totalPages ? totalPages : start;
    } else {
      start = 0;
    }
  }

  const requestData = formatRequestData(start, columnsSearch, currentSortItem);

  return useInfiniteQuery(
    ['telephony_list', columnsSearch, currentSortItem],
    () => fetchTwilioDetails({ requestData }),
    {
      getFetchMore: (lastGroup, allGroups) => {
        return allGroups.length < lastGroup.recordsTotal / pageLength
          ? true
          : null;
      },
      staleTime: 60 * 1000,
      enabled: permissions?.isTelephonyAdmin && procedureName !== 'lfl',
      refetchOnWindowFocus: true,
      onSuccess: () => {
        setLastUpdated(dayjs().format('HH:mm:ss'));
      },
    },
  );
}

export { useGetTelephonyGrid };
