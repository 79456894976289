import { useQuery, QueryResult } from 'react-query';
import { getPhoneTypes } from '../utils/urls';
import { getTokenWithType } from './useAuth';
import { OfficeFactsInput } from 'types/accounts';

async function fetchPhoneTypes(): Promise<APIResult> {
  const response = await fetch(getPhoneTypes, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

export type APIResult = Array<{
  Name: string;
  Description: string;
  Id: string;
}>;
export type TResult = any;

export type TError = { message: string };
export type TVariables = {
  id: number;
};

function useGetPhoneTypes(): QueryResult<TResult, TError> {
  return useQuery(
    ['get_phone_types'],
    async () => {
      const fetchResponse = await fetchPhoneTypes();
      const result = fetchResponse.map(item => ({
        id: item.Id,
        name: `${item.Name}`,
        description: `${item.Description}`,
      }));
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetPhoneTypes };
