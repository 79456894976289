import React, { ReactElement } from 'react';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';
import {
  StyledFormInput,
  FormLabel,
  StyledFormFeedback,
  InputWrapper,
  Prefix,
} from '../../components';
import { FormGroup } from '../index';
import { ValidationRules } from 'react-hook-form';

type ssProps = FlexboxProps & LayoutProps & SpaceProps;

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

// type InputProps = React.DetailedHTMLProps<
//   React.InputHTMLAttributes<HTMLInputElement>,
//   HTMLInputElement
// > & {
//   label: string;
//   register: ({ required }: { required?: boolean }) => RefReturn;
// };

export type InputProps = ssProps & {
  name?: string;
  label?: string | any;
  value?: string | number;
  onTextChange?: (text: string) => void;
  type?: 'email' | 'number' | 'password' | 'search' | 'text' | 'tel' | 'url';
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  validation?: 'error' | 'success' | 'warning';
  feedback?: string;
  required?: boolean;
  register?: (params: ValidationRules) => RefReturn;
  maxLength?: number;
  // validate?: { pattern?: { value?: string; message?: string } };
  validate?: ValidationRules;
  style?: React.CSSProperties;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  prefix?: string;
  onBlur?: () => void;
  className?: string;
  length?: number;
};

const FormInput = ({
  name,
  label,
  value,
  onTextChange,
  type,
  placeholder,
  disabled,
  validation,
  feedback,
  required,
  register,
  maxLength,
  validate,
  style,
  onKeyPress,
  prefix,
  onBlur,
  readOnly,
  className,
  length,
  ...ssProps
}: InputProps): ReactElement => {
  const id = `toggle-${label?.replace(/ /g, '').toLowerCase()}${name}`;
  return (
    <FormGroup style={style} {...ssProps} className={className}>
      {label ? (
        <FormLabel htmlFor={id} required={required} disabled={disabled}>
          {label}
        </FormLabel>
      ) : null}
      <InputWrapper>
        {prefix && <Prefix>{prefix}</Prefix>}
        <StyledFormInput
          maxLength={maxLength}
          name={name || label}
          type={type}
          placeholder={placeholder}
          value={value}
          id={id}
          disabled={disabled}
          validation={validation}
          onChange={e => onTextChange && onTextChange(e.currentTarget.value)}
          ref={
            register &&
            register({
              required: required && `The ${label} is required.`,
              minLength: length && {
                value: length,
                message: `The ${label} requires 4 characters.`,
              },
              ...validate,
            })
          }
          onKeyPress={onKeyPress}
          pretext={!!prefix}
          onBlur={onBlur}
          readOnly={readOnly}
        />
      </InputWrapper>
      {feedback ? (
        <StyledFormFeedback validation={validation} disabled={disabled}>
          {feedback}
        </StyledFormFeedback>
      ) : null}
    </FormGroup>
  );
};

export { FormInput };
