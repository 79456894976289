import { useQuery, QueryResult } from 'react-query';
import { getTokenWithType } from './useAuth';

import { getCountry } from '../utils/urls';

import { CountryType } from '../types/countryType';

async function fetchCountry(): Promise<TResult> {
  const response = await fetch(getCountry, {
    method: 'GET',
    headers: {
      Authorization: getTokenWithType(),
    },
  });
  return response.json();
}

type TVaribles = {
  token: string;
  tokenType: string;
};

// type TResult = Array<CountryType>;

type TResult = any;
type TError = { message: string };

function useGetCountryList(): QueryResult<TResult | TError> {
  return useQuery(
    ['get_country'],
    async () => {
      const result = fetchCountry();
      return result;
    },
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useGetCountryList };
