import React, { ReactElement, useMemo, useState, useCallback } from 'react';
import { queryCache } from 'react-query';
import { Flex, Text, Heading, Box } from 'rebass/styled-components';
import {
  MainContent,
  PageHeader,
  Card,
  Loader,
  EmptyState,
  Table,
  PageContent,
} from 'ui/patterns';
import NoDataImage from 'assets/images/NoDataImage';
import { Separator, Button } from 'ui/components';
import {
  useDocumentTitle,
  useGetReleaseNotes,
  useGetTelephonyGrid,
} from 'hooks';
import { Improvements } from './styles';
import dayjs from 'dayjs';
import useTelephonyLogColumns from './useTelephonyLogColumns';
import Icons from 'assets/icons';
import { AboutTabs } from './AboutTab';

// import { TableData } from './sampleData';

const sortItem = { sortKey: 'Updated At', sortOrder: 'desc' };

export default function About(): ReactElement {
  useDocumentTitle('About', true);

  const [columnsSearch, setColumnsSearch] = useState({}); // header search
  const [currentSortItem, setCurrentSortItem] = useState(sortItem);
  const [lastUpdated, setLastUpdated] = useState<string>();

  const { data: releaseNotes, isLoading } = useGetReleaseNotes();
  const {
    data: telephonyData,
    fetchMore,
    isLoading: isTelephonyLoading,
    canFetchMore,
    isFetchingMore,
    isFetching,
    refetch,
  } = useGetTelephonyGrid({
    columnsSearch,
    currentSortItem,
    setLastUpdated,
  });

  let permissions = [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissions = JSON.parse(permissionSet);
  }

  const columns = useTelephonyLogColumns();

  const tableData = useMemo(() => {
    if (telephonyData) {
      return telephonyData.map(dataItem => dataItem.data).flat();
    }
  }, [telephonyData]);

  const totalItems = useMemo(() => {
    if (telephonyData) {
      return telephonyData[0].recordsTotal;
    }
    return 0;
  }, [telephonyData]);
  console.log('tableData', tableData);

  const onEndReached = () => {
    if (tableData) {
      if (canFetchMore && tableData?.length < totalItems) fetchMore();
    }
  };

  const headerUpdateCallBack = useCallback(
    ({ columnId, value }: { columnId: string; value: string }) => {
      setColumnsSearch({
        ...columnsSearch,
        [columnId]: value,
      });
    },
    [columnsSearch],
  );
  const handleSortChange = useCallback(
    (item: string): void => {
      let sortOrder = 'asc';
      if (
        currentSortItem.sortKey === item &&
        currentSortItem.sortOrder === 'asc'
      ) {
        sortOrder = 'desc';
      }
      setCurrentSortItem({
        sortKey: item,
        sortOrder,
      });
    },
    [setCurrentSortItem, currentSortItem],
  );

  //reset filters
  function resetFilters() {
    queryCache.removeQueries([
      'telephony_list',
      columnsSearch,
      currentSortItem,
    ]);
    setCurrentSortItem(sortItem);
    setColumnsSearch({});
    refetch();
  }

  //refresh list
  function refetchList() {
    queryCache.removeQueries([
      'telephony_list',
      columnsSearch,
      currentSortItem,
    ]);
    refetch();
  }

  function renderReleaseNotes<ReactElement>() {
    return (
      <>
        {!isLoading && releaseNotes?.length !== 0 ? (
          <Card p="5" minWidth="350px" maxWidth="500px">
            {releaseNotes &&
              releaseNotes.length > 0 &&
              releaseNotes?.map((releaseItem, releaseIndex) => (
                <>
                  <Heading as="h2" fontWeight="bold" mb={2} color="text.body">
                    {releaseItem.Version}
                    <Text
                      as="small"
                      fontSize={2}
                      fontWeight="medium"
                      color="var(--color-neutral-contrast-05)"
                      ml={2}
                    >
                      {`Released on ${dayjs(releaseItem.ReleasedOn).format(
                        'MM/DD/YYYY',
                      )}`}
                    </Text>
                  </Heading>
                  <Improvements>
                    {releaseItem?.ReleaseNotes &&
                      releaseItem?.ReleaseNotes.length > 0 &&
                      releaseItem?.ReleaseNotes?.map((noteItem, noteIndex) => (
                        <li key={noteIndex}>{noteItem}</li>
                      ))}
                  </Improvements>
                  {releaseIndex !== releaseNotes?.length - 1 ? (
                    <Separator direction="x" my={6} />
                  ) : null}
                </>
              ))}
          </Card>
        ) : null}

        {isLoading && <Loader />}
        {!isLoading && releaseNotes && releaseNotes.length === 0 && (
          <EmptyState>
            <NoDataImage feedback="No data available" />
          </EmptyState>
        )}
      </>
    );
  }

  function telephonyLogGrid<ReactElement>() {
    return (
      <Flex flexDirection="column" height="100%">
        <Flex
          pb={2}
          justifyContent="space-between"
          alignItems={'baseline'}
          style={{ gap: '1ch' }}
        >
          <Flex style={{}} alignItems="baseline">
            {lastUpdated !== undefined && (
              <p>
                Log last refreshed at <strong>{`${lastUpdated}`}</strong> Please
                Refresh for the latest changes.
              </p>
            )}
            <Button variant="link" onClick={() => refetchList()}>
              Refresh list
            </Button>
          </Flex>
          <Button
            variant="secondary"
            iconBefore={<Icons.Refresh />}
            onClick={() => resetFilters()}
          >
            Reset filters
          </Button>
        </Flex>
        <Table
          columns={columns}
          data={tableData}
          headerUpdateCallBack={headerUpdateCallBack}
          handleSortChange={handleSortChange}
          sortedItem={currentSortItem}
          columnsSearch={columnsSearch}
          isLoading={isTelephonyLoading}
          isFetching={!!isFetchingMore}
          onEndReached={onEndReached}
          noOptions
          hasInlineLoader
        />
      </Flex>
    );
  }

  const tabContentsForFullPermission = [
    {
      id: '1',
      label: 'Release Notes',
      content: renderReleaseNotes(),
      defaultSelected: true,
    },
    {
      id: '2',
      label: 'Telephony Log',
      content: telephonyLogGrid(),
      defaultSelected: false,
    },
  ];

  const tabContents = [
    {
      id: '1',
      label: 'Release Notes',
      content: renderReleaseNotes(),
      defaultSelected: true,
    },
  ];
  const procedureName = process.env.REACT_APP_THEME;

  return (
    <MainContent flexDirection="column">
      {/* <PageHeader title="Release notes" /> */}
      {/* <Flex
        flex="1 1 auto"
        height="1px"
        overflowY="auto"
        p={5}
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <Tabs tabs={tabContents} />
      </Flex> */}
      <PageContent>
        <AboutTabs
          tabs={
            procedureName !== 'lfl' && permissions?.isTelephonyAdmin
              ? tabContentsForFullPermission
              : tabContents
          }
        />
      </PageContent>
    </MainContent>
  );
}
