import { useQuery, QueryResult } from 'react-query';
import { getTwilioPermissionDetails } from '../utils/urls';
import { getTokenWithType } from './useAuth';

async function fetchTwilioPermission(accountId: number): Promise<any> {
  if (accountId) {
    const response = await fetch(getTwilioPermissionDetails(accountId), {
      method: 'GET',
      headers: {
        Authorization: getTokenWithType(),
      },
    });
    return response.json();
  }
}

export type TError = { message: string };

function useGetTwilioDetails(accountId: number): QueryResult<any, TError> {
  return useQuery(
    ['get_twilio_info', accountId],
    async () => {
      const result = await fetchTwilioPermission(accountId);
      console.log('result', result);
      const twilioData = {
        TwilioBranchOutConditionsId:
          result?.TwilioAccountBranchOutCondition?.TwilioBranchOutConditionsId,
        TwilioWorkFlowId: result?.TwilioAccountWorkFlow?.TwilioWorkFlowId,
        TwilioContactNumbers: result?.TwilioContactNumbers?.map(
          (item: any) => ({
            PhoneTypeId: item?.PhoneTypeId,
            CountryId: item?.CountryId,
            PhoneNumber: item?.PhoneNumber,
            Id: item?.Id,
            // IsDeleted: item?.IsDeleted === null ? false : true,
          }),
        ),
        EnableRecording: result?.EnableRecording,
      };
      console.log('twilio dataaa', twilioData);
      return twilioData;
    },

    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: true,
      enabled: accountId !== undefined || accountId !== null,
    },
  );
}

export { useGetTwilioDetails };
