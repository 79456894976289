import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { DropdownButton, DropdownMenu, StyledDropdown } from 'ui/patterns';
import { Button, Checkbox } from 'ui/components';
import { Heading, Box } from 'rebass/styled-components';
import { DotsVertical } from 'assets/icons';
import styled from 'styled-components';
import { useSaveUserSettings } from 'hooks/useSaveUserSettings';
import useOnClickOutside from 'use-onclickoutside';
import { showWarningToast } from 'utils/showToast';

const columns = [
  'Phone Display',
  'CID',
  'SID',
  'PID',
  'Answer Script',
  'Account Name',
  'Rel Manager',
  'City',
  'State',
  'Time',
  'Status',
  'Service',
  'Routing Group',
  'Branch-out Group',
  'Call Recording',
];

const columnSet = [
  'Phone Display',
  'CID',
  'SID',
  'PID',
  'Answer Script',
  'Account Name',
  'Rel Manager',
  'City',
  'State',
  'Time',
  'Status',
  'Service',
];

const DDWrapper = styled(Box)`
  /* .dd-wrapper { */
  /* position: fixed; */
  /* right: 38px; */
  /* transform: translate(13px); */
  /* z-index: ${({ theme }) => theme.zIndices[4]}; */
  /* } */

  .dd-list {
    & > * + * {
      margin-top: ${({ theme }) => theme.space[1]};
    }
  }

  .dd-header {
    border-bottom: solid 1px ${({ theme }) => theme.colors.gray[3]};
  }

  .dd-footer {
    border-top: solid 1px ${({ theme }) => theme.colors.gray[3]};
  }

  .dd-col-selection {
    summary {
      background: none;
      border: none;
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[1]};
      line-height: 1.2;
      box-shadow: none;
      display: flex;
      align-items: center;

      &:focus,
      &:active {
        outline: none;
        background: ${({ theme }) => theme.colors.bg.grayDark};
        color: #fff;
      }
    }
  }
`;

export default function TableHeader({
  userSettings,
}: {
  userSettings: Array<string>;
}): ReactElement {
  let permissions = [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissions = JSON.parse(permissionSet);
  }
  const procedureName = process.env.REACT_APP_THEME;
  const visisbleColumns = procedureName !== 'lfl' ? columns : columnSet;
  const onSuccess = () => setOpen(false);
  const [saveSelectedColumns, { isLoading }] = useSaveUserSettings(onSuccess);
  const [selectedOptions, setSelectedOptions] = useState(
    userSettings ? userSettings : columnSet,
  );

  const isChecked = (columnHeader: string): boolean => {
    const item =
      selectedOptions &&
      selectedOptions.find(selectedColumn => selectedColumn === columnHeader);
    if (item) return true;
    else return false;
  };

  const isDisabled = (columnHeader: string): boolean => {
    const item =
      selectedOptions &&
      selectedOptions.find(selectedColumn => selectedColumn === columnHeader);
    if (item && selectedOptions.length <= 1) return true;
    else return false;
  };

  useEffect(() => {
    if (userSettings) {
      console.log('user settings', userSettings);
      setSelectedOptions(userSettings);
    }
  }, [userSettings, setSelectedOptions]);

  const handleChangeCheckbox = (e: React.FormEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value;
    const index = selectedOptions.findIndex(item => item === value);
    if (index > -1) {
      const copySelectedOptions = [...selectedOptions];
      copySelectedOptions.splice(index, 1);
      if (copySelectedOptions && copySelectedOptions.length <= 1)
        showWarningToast({
          message: 'At least one column should be selected',
        });
      setSelectedOptions([...copySelectedOptions]);
    } else {
      setSelectedOptions([...selectedOptions, value]); //selectedOptions.push(value);
    }
  };

  const onApplyClick = () => {
    saveSelectedColumns({ saveRequest: selectedOptions });
  };
  const menuContent = () => (
    <Box>
      <Heading
        as="h6"
        fontSize={1}
        className="dd-header"
        px={3}
        pt={1}
        pb={2}
        sx={{ whiteSpace: 'break-spaces', textAlign: 'left' }}
      >
        Choose columns to show in the table
      </Heading>
      <Box className="dd-list" p={3} sx={{ textAlign: 'left' }}>
        {visisbleColumns?.map(column => (
          <Checkbox
            key={column}
            label={column}
            value={column}
            name={column}
            checked={isChecked(column)}
            handleChange={handleChangeCheckbox}
            disabled={isDisabled(column)}
          />
        ))}
      </Box>
      <Box
        px={3}
        pt={2}
        pb={1}
        className="dd-footer"
        sx={{ textAlign: 'left' }}
      >
        <Button
          testId={'apply-button'}
          onClick={onApplyClick}
          variant="primary"
        >
          {isLoading ? 'Processing' : 'Apply'}
        </Button>
      </Box>
    </Box>
  );

  const [open, setOpen] = useState(false);
  const handleOnClickOutside = () => {
    // console.log('outside');
    setSelectedOptions(userSettings);
    setOpen(false);
  };
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, handleOnClickOutside);
  const handleToggle = (e: any) => {
    setOpen(true);
  };

  return (
    <DDWrapper>
      <StyledDropdown
        ref={wrapperRef}
        onClick={handleToggle}
        className="dd-col-selection"
      >
        <DropdownButton>
          <DotsVertical title="Column selection" />
        </DropdownButton>
        {open && <DropdownMenu>{menuContent()}</DropdownMenu>}
      </StyledDropdown>
    </DDWrapper>
  );
}
